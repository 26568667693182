import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Link, Route , Redirect, Switch} from 'react-router-dom';
import Header from './Components/Screens/Headers/Header';
import Amplify, { Auth } from 'aws-amplify';
import awsConfig from './awsConfig';
import { useState, useEffect } from 'react';
import SignIn from './Components/authentication/SignIn';
import AgentHome from "./Components/Screens/Enrollment/agentHome";
import RootComponent from "./Components/Screens/Enrollment/rootComponent";
import Login from "./Components/Screens/Login/login";
import Welcome from "./Components/Screens/Enrollment/Welcome";
import Prospect from "./Components/Screens/Enrollment/prospect";
import Employer from "./Components/Screens/Enrollment/employer";
import TestComponent from "./Components/Screens/Enrollment/TestComponent";
import Configuration from './configurations'
import TabOpened from './Components/Screens/Enrollment/TabOpened';
global.load=0;

Amplify.configure(awsConfig);

const App = (props) => {
  const [clearSession, setclearSession] = useState(
    localStorage.getItem("CurrentLoginUser"),
  );
    const [showPopup, setShowPopup] = useState(false);
    const [tabAccess, setTabAccess] = useState(false);
    const [tabId, setTabid] = useState(null);
    const [currentTabId, setCurrentTabId] = useState(null);

  useEffect(() => {
    var brand = Configuration.BRAND;
    console.log("Brand---", brand);
    localStorage.setItem("Brand", brand);
    console.log(window.location.pathname);
    var url = window.location.href;
    if (
      (window.location.pathname == "/quick_quote" && clearSession) ||
      (window.location.pathname == "/signup" && clearSession)
    ) {
      window.location.reload(url);
      Auth.signOut();
      localStorage.removeItem("CurrentLoginUser");
    }
    // getQueryParams()
    // debugger
    // Auth.currentAuthenticatedUser()
    //     .then((user) => {
    //         debugger
    //         console.log('=========' + JSON.stringify(user))
    //     }).catch((err)=>{
    //         debugger
    //     })
  });

    useEffect(() => {
        if(Configuration.BRAND == 'netwell'){
            localStorage.setItem("Brand",'netwell')
        }
    })

    useEffect(() => {
        let tab = Date.now().toString();
        setTabid(tab);
        if (localStorage.getItem("currentTabId") === null) {
            localStorage.setItem("currentTabId", tab);
            setCurrentTabId(tab);
            setShowPopup(false);
        }
        if (sessionStorage.getItem("tab") === null) {
            sessionStorage.setItem("tab", tab);
        }
        const channel = new BroadcastChannel("myChannel");
        channel.postMessage(sessionStorage.getItem("tab"));
        channel.addEventListener("message", (event) => {
            if (
                event.data === sessionStorage.getItem("tab") &&
                sessionStorage.getItem("tabExp") !== "true"
            ) {
                let tabReplace = Date.now().toString();
                localStorage.setItem("currentTabId", tabReplace);
                sessionStorage.setItem("tab", tabReplace);
            }
        });

        if (
            sessionStorage.getItem("tab") &&
            localStorage.getItem("currentTabId") === sessionStorage.getItem("tab")
        ) {
            setShowPopup(false);
        } else {
            setShowPopup(true);
        }
        const handleStorageChange = (e) => {
            if (e.key === "currentTabId") {
                if (
                    sessionStorage.getItem("tab") &&
                    localStorage.getItem("currentTabId") === sessionStorage.getItem("tab")
                ) {
                    setShowPopup(false);
                } else if (
                    localStorage.getItem("currentTabId") !== sessionStorage.getItem("tab")
                ) {
                    setShowPopup(true);
                    // setTabAccess(true);
                    // sessionStorage.setItem("tabExp", true)
                } else {
                    if (Boolean(sessionStorage.getItem("tabExp"))) {
                        setShowPopup(true);
                        setTabAccess(true);
                    } else setShowPopup(true);
                }
            }
        };
        window.addEventListener("beforeunload", () => {
            if (
                localStorage.getItem("currentTabId") === sessionStorage.getItem("tab")
            ) {
                localStorage.removeItem("currentTabId");
                sessionStorage.removeItem("tab");
            }
        });
        window.addEventListener("storage", handleStorageChange);
        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, []);

    if (showPopup || Boolean(sessionStorage.getItem("tabExp")))
        return (
            <TabOpened
                tabAccess={tabAccess || Boolean(sessionStorage.getItem("tabExp"))}
                cancel={() => {
                    setShowPopup(true);
                    setTabAccess(true);
                    sessionStorage.setItem("tabExp", true);
                }}
                onAccept={() => {
                    setShowPopup(false);
                    sessionStorage.setItem("showPopup", false);
                    let tab = Date.now().toString();
                    sessionStorage.setItem("tab", tab);
                    localStorage.setItem("currentTabId", tab);
                    // localStorage.setItem('acceptStatus', 'accepted');
                    // localStorage.removeItem('acceptStatus');
                }}
            />
        );
    else
    return (
        <div className="App">
            <BrowserRouter>
            <Switch>
                <Route exact path="/" component={RootComponent} />
                <Route path="/login" component={SignIn} />
                <Route path="/manage-employers" component={Employer} />
                <Route path="/manage-prospects" component={Prospect} />
                {/*<Route path="/agent_home" component={AgentHome} />*/}
                <Route path="/agentdir/:org/:broker" component={Welcome} />
                <Route path="/TestComponent" component={TestComponent} />
                {/* <Route path="/agentdir/tutelachannel/NIL9292" component={Welcome} /> */}
                <Redirect from="/" to="/" />
                </Switch>
            </BrowserRouter>
        </div>
    )
    
}

//export default withAuthenticator(App, true);
export default App;
