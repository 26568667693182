import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import customStyle from "../../../Assets/CSS/stylesheet_UHS";
import Button from "@material-ui/core/Button";
import styles from "../../../Assets/CSS/stylesheet_UHS";
import { Modal } from "react-bootstrap";

const CustomButton = withStyles(customStyle.viewBtn)(Button);

class TabOpened extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabAccess: this.props.tabAccess,
        };
    }
    accessHere = () => {
        this.props.onAccept();
    };
    render() {
        return (
            <>
                <Modal size="md" show={true} centered backdrop="static">
                    <Modal.Header style={styles.modal_header}>
                        <Modal.Title>Message</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ padding: "15px" }}>
                        <div style={customStyle.HomeContainer}>
                            <div style={customStyle.HomeMAinChild}>
                                {!this.props.tabAccess
                                    ? `The Agent Portal is open in another window. Click "Access Here" to continue with this window instead.`
                                    : "This session has expired as the Agent Portal is being accessed in a different window."}
                            </div>
                        </div>
                    </Modal.Body>

                    {!this.props.tabAccess && (
                        <Modal.Footer>
                            <CustomButton
                                style={{
                                    height: "40px",
                                    backgroundColor: "#4782c4",
                                    marginRight: 10,
                                }}
                                onClick={() => this.props.cancel()}
                            >
                                Cancel
                            </CustomButton>
                            <CustomButton
                                style={{ height: "40px", backgroundColor: "#4782c4" }}
                                onClick={() => this.accessHere()}
                            >
                                Access Here
                            </CustomButton>
                        </Modal.Footer>
                    )}
                </Modal>
            </>
        );
    }
}

export default TabOpened;
